import { Actions } from "easy-peasy";
import { StoreModel } from "../../../../store/model/interfaces";
import { RecursiveActionsOptions } from "../types";

const getThunkActions = (storeActions: Actions<StoreModel>) => {
  const actions: string[] = [];
  Object.keys(storeActions).forEach((storeName) => {
    const store = storeActions[storeName as keyof StoreModel];
    Object.keys(store).forEach((actionKey) => {
      const actionObject = store[actionKey as keyof RecursiveActionsOptions];
      Object.keys(actionObject).forEach((actionObjectProps) => {
        const action = actionObject[actionObjectProps] as string;
        if (
          action.toString().includes("(success)") &&
          action.toString().includes("@thunk") &&
          !(
            action.toString().includes("(fail)") ||
            action.toString().includes("(start)")
          )
        ) {
          actions.push(action);
        }
      });
    });
  });

  return actions;
};

export default getThunkActions;
