import { TableCellProps } from "@mui/material";
import { GetCellPropsHelper } from "../interfaces";

const getCellProps = ({
  labelId,
  isFirstElement,
  isNumeric,
}: GetCellPropsHelper): TableCellProps => {
  const firstElementProps: TableCellProps = {
    id: labelId,
    component: "th",
    scope: "row",
    padding: "none",
  };

  const restElementsProps: TableCellProps = {
    align: isNumeric ? "right" : "left",
  };

  return isFirstElement ? firstElementProps : restElementsProps;
};
export default getCellProps;
