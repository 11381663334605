import { Category } from "../../../../store/model/interfaces";

import { CategoryNames } from "../../../../enums/categoryEnums";
import { columnsComputers, columnsMonitors, columnsPhones } from "../constants";

export const getAllColumnsCSV = ({
  name: categoryName,
  attributes,
}: Category) => {
  let columnsCSV: string[] = [];
  if (categoryName === CategoryNames.computers)
    columnsCSV = JSON.parse(JSON.stringify(columnsComputers));
  else if (categoryName === CategoryNames.monitors)
    columnsCSV = JSON.parse(JSON.stringify(columnsMonitors));
  else if (categoryName === CategoryNames.phones)
    columnsCSV = JSON.parse(JSON.stringify(columnsPhones));
  attributes.map((item) => {
    columnsCSV.push(item.name);
  });

  return columnsCSV;
};
