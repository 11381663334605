import { HeadCell } from "../../molecules/TableHead/interfaces";

export const pathComputersTemplate =
  "https://docs.google.com/spreadsheets/d/135L464lqUWX5uXNbkzzSdvsqahRL6SHm95C9iAwrYFw/edit#gid=0";
export const pathMonitorsTemplate =
  "https://docs.google.com/spreadsheets/d/1r2tI9GQu_iAF5KFSXfcXy0Ou-V-Xr24Gce0282DZVCQ/edit#gid=0";
export const pathPhonesTemplate =
  "https://docs.google.com/spreadsheets/d/1m5cjkz62iuXIRogMnpnd0qY_l3kEr3Dx2jo0bsIoBRE/edit#gid=0";

export const rowsD = [
  { id: 1, type: "Camera", total: 10, available: 5 },
  { id: 1, type: "Mouse", total: 10, available: 5 },
  { id: 1, type: "Hub", total: 10, available: 5 },
  { id: 1, type: "Keyboard", total: 10, available: 5 },
];

export const headCellsD: readonly HeadCell[] = [
  {
    id: "tag",
    isNumeric: false,
    disablePadding: true,
    label: "Tag",
    isFirst: true,
  },
  {
    id: "errors",
    isNumeric: false,
    disablePadding: false,
    label: "Message Error",
    isError: true,
  },
  {
    id: "id_columns",
    isNumeric: false,
    disablePadding: false,
    label: "Id Columns",
  },
];

export const columnsComputers = [
  "owner",
  "tag",
  "serial",
  "type",
  "model",
  "state",
  "location",
  "acquisition_date",
  "acquisition_price",
  "current_worth",
  "provider",
  "invoice_num",
  "warranty_time",
  "notes",
  "current_user",
  "seat",
  // the following columns should be displayed dynamically from the attribute table
  // "ram",
  // "ssd",
  // "processor",
  // "display_size",
  // "ports",
];

export const columnsMonitors = [
  "owner",
  "tag",
  "serial",
  "type",
  "model",
  "state",
  "location",
  "acquisition_date",
  "acquisition_price",
  "current_worth",
  "provider",
  "invoice_num",
  "warranty_time",
  "notes",
  "current_user",
  "seat",
  // the following columns should be displayed dynamically from the attribute table
  // "display_size",
  // "ports",
];

export const columnsPhones = [
  "owner",
  "tag",
  "serial",
  "type",
  "model",
  "state",
  "location",
  "acquisition_date",
  "acquisition_price",
  "current_worth",
  "provider",
  "invoice_num",
  "warranty_time",
  "notes",
  "current_user",
  "seat",
  // the following columns should be displayed dynamically from the attribute table
  // "ram",
  // "ssd",
  // "processor",
  // "display_size",
  // "ports",
];
