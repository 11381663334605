import { GenericTableData } from "../../../organisms/Table/interfaces";
import { Order } from "../../../organisms/Table/types";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
}

function getComparator<Key extends keyof GenericTableData>(
  order: Order,
  orderBy: Key
): (
  // eslint-disable-next-line no-unused-vars
  _a: { [key in Key]: number | string | boolean },
  // eslint-disable-next-line no-unused-vars
  _b: { [key in Key]: number | string | boolean }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export default getComparator;
