import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as MUISelect,
} from "@mui/material";
import { SelectProps } from "./interfaces";

const Select = ({ options, name, label, value, onChange }: SelectProps) => (
  <FormControl fullWidth>
    <InputLabel id={`select-${name}`}>{label}</InputLabel>
    <MUISelect
      labelId={`select-${name}`}
      id={`select-${name}-id`}
      name={name}
      value={value}
      label={label}
      onChange={onChange}
    >
      {options.map(({ id, label }) => (
        <MenuItem key={id} value={id}>
          {label}
        </MenuItem>
      ))}
    </MUISelect>
  </FormControl>
);

export default Select;
