import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Dialog from "../Dialog";
import { Button, Label, Alert } from "../../atoms";
import { Loader } from "..";
import Table from "../Table";
import { ErrorListData, ImportFileDialogProps } from "./interfaces";

import { styledSubmitButton } from "./styles";
import { HeadCell } from "../../molecules/TableHead/interfaces";
import { GenericTableData } from "../Table/interfaces";

const ImportFileDialog = ({
  open,
  handleClose,
  isValid,
  handleFileSelect,
  buttonSubmitDisabled,
  generalErrors,
  errors,
}: ImportFileDialogProps) => {
  const [isloading, setIsloading] = useState(false);
  const dataHead: HeadCell[] = [
    {
      id: "tag",
      isNumeric: false,
      disablePadding: false,
      label: "row",
      rowElement: (cell) => {
        const { tag } = cell as unknown as ErrorListData;

        return <Typography>{tag}</Typography>;
      },
    },
    {
      id: "errors",
      isNumeric: false,
      disablePadding: false,
      label: "errors",
      rowElement: (cell) => {
        const { errors } = cell as unknown as ErrorListData;

        return (
          <Typography>
            {errors.map((valor, index) => (
              <p key={index}>{valor.message}</p>
            ))}
          </Typography>
        );
      },
    },
    {
      id: "id_columns",
      isNumeric: false,
      disablePadding: false,
      label: "columns",
      rowElement: (cell) => {
        const { errors } = cell as unknown as ErrorListData;

        return (
          <Typography>
            {errors.map((valor, index) => (
              <p key={index}>{valor.header}</p>
            ))}
          </Typography>
        );
      },
    },
  ];

  useEffect(() => {
    if (isValid !== null) {
      setTimeout(() => {
        setIsloading(false);
      }, 1000);
    }
  }, [isValid]);

  return (
    <Dialog type="Info" open={open} handleClose={handleClose} maxWidth={"lg"}>
      <Button variant="contained" component="label">
        Upload File
        <input
          name="file-upload"
          type="file"
          hidden
          data-testid="input-upload-csv"
          onChange={(event) => {
            setIsloading(true);
            handleFileSelect(event);
          }}
        />
      </Button>
      <Button
        onClick={handleClose}
        disabled={buttonSubmitDisabled}
        style={styledSubmitButton}
      >
        SUBMIT
      </Button>
      {isValid === null ? (
        <Box display="flex" justifyContent="center">
          <Label marginY={4}>Please, select a file to import.</Label>
        </Box>
      ) : null}
      {isValid === true ? (
        <Box display="flex" justifyContent="center">
          <Label color="green" marginY={4}>
            The file is ready to be sent.
          </Label>
        </Box>
      ) : null}
      {isValid === false ? (
        <>
          <Box display="flex" justifyContent="center">
            <Label color="red" marginY={2}>
              Some errors were found in the file records.
            </Label>
          </Box>
          {!!generalErrors.length && (
            <Alert severity="error" variant="outlined" title="CSV errors">
              <ul>
                {generalErrors.map((item, index) => (
                  <li
                    key={`item-gen-error-${index}`}
                    data-testid={`item-gen-error-${index}`}
                  >
                    {item}
                  </li>
                ))}
              </ul>
            </Alert>
          )}
          {isloading ? (
            <Box display="flex" paddingTop={5}>
              <Loader loading />
            </Box>
          ) : errors?.length ? (
            <Table
              cellsData={dataHead}
              rowsData={errors as unknown as GenericTableData[]}
            />
          ) : null}
        </>
      ) : null}
    </Dialog>
  );
};

export default ImportFileDialog;
