import { useState } from "react";
import { AddCircle, RemoveCircle } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { Input, Select } from "../../components/atoms";
import { InventoryAction } from "./enums";
import {
  getInventoryByOfficeAndEquipment,
  formatOptionsForOffices,
  handleDisableInventoryBtn,
} from "./helpers/equipmentManagementHelpers";
import { PeripheralsInfoFormComponentProps } from "./interfaces";

const PeripheralsInfoForm = ({
  equipment,
  offices,
  officeSelected,
  quantityToRemove,
  quantityToAdd,
  inventoryData,
  handleOfficeChange,
  handleUpdateInventory,
  handleInventoryChange,
}: PeripheralsInfoFormComponentProps) => {
  const [isDisabledAddBtn, setIsDisabledAddBtn] = useState<boolean>(true);
  const [isDisabledRemoveBtn, setIsDisabledRemoveBtn] = useState<boolean>(true);

  const assignedOffices = getInventoryByOfficeAndEquipment(
    inventoryData,
    officeSelected,
    equipment,
    "QTY"
  );
  const available = getInventoryByOfficeAndEquipment(
    inventoryData,
    officeSelected,
    equipment,
    "AVAILABLE"
  );

  return (
    <>
      <Select
        name="office"
        label="Select Office"
        type="date"
        options={formatOptionsForOffices(offices)}
        value={officeSelected.office_id}
        onChange={handleOfficeChange}
      />
      <br />
      <Input
        disabled
        name="total_office"
        label="Total in Office"
        type="number"
        value={assignedOffices}
      />
      <Input
        disabled
        name="available_office"
        label="Available in office"
        type="number"
        value={available.toString()}
      />
      <br></br>
      <Input
        name="add_inventory"
        label="Add to inventory"
        type="number"
        value={quantityToAdd}
        sufix={
          <Tooltip title="Add" arrow placement="top-start">
            <span>
              <IconButton
                data-testid="add-inventory-button"
                disabled={isDisabledAddBtn}
                onClick={() => {
                  handleUpdateInventory(InventoryAction.ADD);
                  setIsDisabledAddBtn(true);
                }}
              >
                <AddCircle color={isDisabledAddBtn ? "disabled" : "primary"} />
              </IconButton>
            </span>
          </Tooltip>
        }
        onChange={(event) => {
          handleDisableInventoryBtn(
            event,
            InventoryAction.ADD,
            available,
            setIsDisabledAddBtn
          );
          handleInventoryChange(event, InventoryAction.ADD);
        }}
      />
      <Input
        name="remove_inventory"
        label="Remove from inventory"
        type="number"
        value={quantityToRemove}
        sufix={
          <Tooltip title="Remove" arrow placement="top-start">
            <span>
              <IconButton
                data-testid="remove-inventory-button"
                disabled={isDisabledRemoveBtn}
                onClick={() => {
                  handleUpdateInventory(InventoryAction.REMOVE);
                  setIsDisabledRemoveBtn(true);
                }}
              >
                <RemoveCircle
                  color={isDisabledRemoveBtn ? "disabled" : "primary"}
                />
              </IconButton>
            </span>
          </Tooltip>
        }
        onChange={(event) => {
          handleDisableInventoryBtn(
            event,
            InventoryAction.REMOVE,
            available,
            setIsDisabledRemoveBtn
          );
          handleInventoryChange(event, InventoryAction.REMOVE);
        }}
      />
    </>
  );
};

export default PeripheralsInfoForm;
