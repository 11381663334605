import { Grid } from "@mui/material";
import { ContainerProps } from "./interfaces";

const Container = ({ children }: ContainerProps) => (
  <Grid
    className="container"
    container
    direction="column"
    justifyContent="center"
    alignItems="center"
    paddingTop={2}
  >
    {children}
  </Grid>
);

export default Container;
