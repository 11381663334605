import { action, computed, thunk } from "easy-peasy";
import { orderBy } from "lodash";
import { CategoryModel, CategoryBySubcategoryName } from "./interfaces";

const category: CategoryModel = {
  data: null,
  addCategories: action((state, payload) => {
    let orderlyPayload = payload;
    if (payload)
      orderlyPayload = payload.map((item) => ({
        ...item,
        attributes: orderBy(item.attributes, ["order_num"], ["asc"]),
      }));
    state.data = orderlyPayload;
  }),
  byId: computed((state) => {
    const categoriesCopy = [...(state.data || [])];

    return categoriesCopy.reduce((byId, category) => {
      byId = { ...byId, [category.id]: category };

      return byId;
    }, {});
  }),
  byName: computed((state) => {
    const categoriesCopy = [...(state.data || [])];

    return categoriesCopy.reduce((byName, category) => {
      byName = { ...byName, [category.name]: category };

      return byName;
    }, {});
  }),
  bySubcategoryName: computed((state) => {
    const categoryCopy = [...(state.data || [])];
    const bySeatName: CategoryBySubcategoryName = {};
    categoryCopy.map((category) => {
      const { sub_categories } = category;
      sub_categories.map((subCategory) => {
        bySeatName[subCategory.name] = category;
      });
    });

    return bySeatName;
  }),
  getCategories: thunk(async (actions, _, { injections }) => {
    const data = await injections.api.getCategories();
    actions.addCategories(data);
  }),
};

export default category;
