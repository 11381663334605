import { MenuItem, Switch } from "@mui/material";
import Menu from "../../atoms/Menu";
import Label from "../../atoms/Label";
import { SettingsMenuProps } from "./interfaces";

const SettingsMenu = ({
  anchorEl,
  onClose,
  onClickMenu,
  isDarkTheme,
  changeTheme,
  open,
  settings,
}: SettingsMenuProps) => {
  return (
    <Menu
      sx={{ mt: "45px" }}
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={open}
      onClose={onClose}
    >
      {settings.map(({ label, id }) => (
        <MenuItem key={id} onClick={() => onClickMenu(id)}>
          <Label centerText>{label}</Label>
        </MenuItem>
      ))}
      <MenuItem key="item-change-theme" onClick={changeTheme}>
        <Label centerText>
          Change theme
          <Switch checked={isDarkTheme} />
        </Label>
      </MenuItem>
    </Menu>
  );
};

export default SettingsMenu;
