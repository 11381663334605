import { Link as RouterLink } from "react-router-dom";
import { MenuItem } from "@mui/material";
import Menu from "../../atoms/Menu";
import { PagesMenuProps } from "./interfaces";

const PagesMenu = ({
  anchorEl,
  onClose,
  onClickMenuItem,
  open,
  pages,
}: PagesMenuProps) => {
  return (
    <Menu
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      sx={{
        display: { xs: "block", md: "none" },
      }}
      onClose={onClose}
      open={open}
    >
      {pages.map(({ name, label, link }) => (
        <MenuItem
          component={RouterLink}
          to={link}
          key={name}
          onClick={() => {
            onClickMenuItem();
          }}
        >
          {label}
        </MenuItem>
      ))}
    </Menu>
  );
};

export default PagesMenu;
