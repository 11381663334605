import { createStore } from "easy-peasy";
import category from "./category";
import equipment from "./equipment";
import office from "./office";
import owner from "./owner";
import people from "./people";
import status from "./status";
import loader from "./loader";
import * as api from "../../services/apiService";
import * as reservationsApi from "../../services/reservationsApiService";
import { StoreModel } from "./interfaces";

export const storeModel: StoreModel = {
  category,
  equipment,
  loader,
  office,
  owner,
  people,
  status,
};

const store = createStore(storeModel, {
  injections: {
    api,
    reservationsApi,
  },
});

export default store;
