import { useEffect, useState } from "react";
import { Container, Label } from "../../components/atoms";
import {
  Dialog,
  ImportFile,
  PreLoader,
  Table,
  Tabs,
} from "../../components/organisms";
import { GenericTableData } from "../../components/organisms/Table/interfaces";
import { HeadCell } from "../../components/molecules/TableHead/interfaces";
import { EquipmentManagementComponentProps } from "./interfaces";
import EquipmentInformationComponent from "./EquipmentInformationComponent";
import { Equipment } from "../../store/model/interfaces";
import {
  generateGenericData,
  generatePeripheralsData,
  isPeripheral,
} from "./helpers/equipmentManagementHelpers";
import { generateTableHeaderFn } from "./helpers/equipmentManagementComponentHelpers";

const EquipmentManagementComponent = ({
  categories,
  categoryBySubcategoryName,
  categoriesById,
  equipment,
  officeList,
  equipmentById,
  officeBySeatName,
  ownerByName,
  peopleByEmail,
  statusByName,
  inventoryData,
  onCategoryChange,
  addEquipmentInventory,
  removeEquipmentInventory,
}: EquipmentManagementComponentProps) => {
  const [tableHeaders, setTableHeaders] = useState<HeadCell[]>([]);
  const [tableRows, setTableRows] = useState<GenericTableData[]>([]);
  const [categoryId, setCategoryId] = useState(categories[0]?.id);
  const [selectedEquipementId, setSelectedEquipementId] = useState<
    number | null
  >();

  const [equipmentData, setEquipmentData] = useState<Equipment>();

  useEffect(() => {
    if (equipment) setTableRows(generateTableRows());
  }, [equipment]);

  useEffect(() => {
    if (selectedEquipementId)
      setEquipmentData(equipmentById[selectedEquipementId]);
  }, [selectedEquipementId]);

  useEffect(() => {
    onCategoryChange(categoryId);
    setTableHeaders(
      generateTableHeaderFn(categoryId, setSelectedEquipementId, categories)
    );
  }, [categoryId]);

  const generateTableRows = (): GenericTableData[] => {
    const categoryName = categoriesById[categoryId].name;
    let dataGeneratorFunction;
    if (isPeripheral(categoryName)) {
      dataGeneratorFunction = generatePeripheralsData;
    } else {
      dataGeneratorFunction = generateGenericData;
    }

    return equipment.map(
      dataGeneratorFunction
    ) as unknown as GenericTableData[];
  };

  const generateTabsData = () => {
    return categories.map(({ id, label: header }) => ({
      id,
      header,
      content: equipment.length ? (
        <PreLoader properties={["getEquipmentsByCategory"]}>
          <Table cellsData={tableHeaders} rowsData={tableRows} />
        </PreLoader>
      ) : null,
    }));
  };

  return (
    <Container>
      <Label>Equipment Management</Label>
      <Tabs
        data={generateTabsData()}
        onChangeTab={(categoryId: number) => {
          setCategoryId(categoryId);
        }}
      />
      {equipmentData && selectedEquipementId && (
        <Dialog
          title={equipmentData.tag || equipmentData.sub_category.label}
          type="Info"
          open
          handleClose={() => {
            setSelectedEquipementId(null);
            onCategoryChange(categoryId);
          }}
        >
          <EquipmentInformationComponent
            category={categoriesById[categoryId]}
            equipment={equipmentData}
            inventoryData={inventoryData}
            setEquipment={setEquipmentData}
            offices={officeList}
            addEquipmentInventory={addEquipmentInventory}
            removeEquipmentInventory={removeEquipmentInventory}
          />
        </Dialog>
      )}
      <ImportFile
        category={categoriesById[categoryId]}
        categoryBySubcategoryName={categoryBySubcategoryName}
        officeBySeatName={officeBySeatName}
        ownerByName={ownerByName}
        peopleByEmail={peopleByEmail}
        statusByName={statusByName}
      />
    </Container>
  );
};

export default EquipmentManagementComponent;
