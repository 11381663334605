import { Button as MUIButton } from "@mui/material";
import { MUIButtonProps } from "./interfaces";

const Button = ({
  children,
  variant,
  small,
  component = "button",
  target,
  ...rest
}: MUIButtonProps) => (
  <MUIButton
    className="login-button"
    variant={variant ? variant : undefined}
    size={small ? "small" : "large"}
    component={component}
    target={target}
    {...rest}
  >
    {children}
  </MUIButton>
);

export default Button;
