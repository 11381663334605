import { AssignmentPeople, AssignmentOffice } from "../enums";
import { peripheralHeaders } from "../constants";

export const generateTableHeader = (assignmentId: number) => {
  let headerTitles = [
    AssignmentPeople.FIRST_HEADER,
    AssignmentPeople.SECOND_HEADER,
    ...peripheralHeaders,
  ];
  const officeIndex = 1;

  if (assignmentId === officeIndex) {
    headerTitles = [
      AssignmentOffice.FIRST_HEADER,
      AssignmentOffice.SECOND_HEADER,
      ...headerTitles.slice(2),
    ];
  }

  const headCells = headerTitles.map((title) => ({
    id: title,
    label: title.toUpperCase(),
    isNumeric: false,
    disablePadding: false,
    isFirst: true,
  }));

  return headCells;
};
