import axios from "axios";
import EnvManager from "../config/envManager";

export const reservationsApi = axios.create({
  baseURL: EnvManager.RESERVATIONS_URL,
});

export const getOffices = async () => {
  try {
    const response = await reservationsApi.get("/office-inventory");

    return response?.status === 200 ? JSON.parse(response?.data.body) : null;
  } catch (error) {
    // console.log("getUserPermissions error:", error);
  }
};
