import axios from "axios";
import EnvManager from "../config/envManager";

const authApi = axios.create({
  baseURL: EnvManager.BACKEND_URL,
  withCredentials: true,
});

export const getUserPermissions = async () => {
  try {
    const response = await authApi.get("/auth/user-permissions");

    return response?.status === 200 ? response?.data : null;
  } catch (error) {
    // console.log("getUserPermissions error:", error);
  }
};

export const logoutUser = async () => {
  try {
    await authApi.get("/auth/logout");
  } catch (error) {
    // console.log("logoutUser error:", error);
  }
};
