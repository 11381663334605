import {
  Checkbox,
  TableBody as MUITableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { TableBodyProps } from "./interfaces";
import { getComparator, getCellProps } from "./helpers";
import { StyledTableCell } from "./styles";

const TableBody = ({
  allowSelection,
  cellsData,
  rowsData,
  order,
  orderBy,
  selected,
  page,
  rowsPerPage,
  setSelected,
}: TableBodyProps) => {
  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rowsData.length) : 0;

  const handleClick = (id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  return (
    <MUITableBody>
      {rowsData
        .slice()
        .sort(getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row, index) => {
          const isItemSelected = isSelected(row.id);
          const labelId = `table-checkbox-${index}`;

          return (
            <TableRow
              hover
              onClick={() => {
                allowSelection && handleClick(row.id);
              }}
              role="checkbox"
              aria-checked={isItemSelected}
              tabIndex={-1}
              key={index}
              selected={isItemSelected}
            >
              {allowSelection && (
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    checked={isItemSelected}
                    inputProps={{
                      "aria-labelledby": labelId,
                    }}
                  />
                </TableCell>
              )}
              {cellsData.map(
                ({ id, isFirst, rowElement, isError, isNumeric }) => {
                  const firstCellProps = getCellProps({
                    labelId,
                    isFirstElement: isFirst,
                    isNumeric,
                  });

                  return (
                    <StyledTableCell
                      key={id}
                      className={isError ? "error" : ""}
                      {...firstCellProps}
                    >
                      {rowElement ? rowElement(row) : row[id] || ""}
                    </StyledTableCell>
                  );
                }
              )}
            </TableRow>
          );
        })}
      {emptyRows > 0 && (
        <TableRow
          style={{
            height: 53 * emptyRows,
          }}
        >
          <TableCell colSpan={6} />
        </TableRow>
      )}
    </MUITableBody>
  );
};

export default TableBody;
