import { useState } from "react";
import { Container, Label } from "../../components/atoms";
import { Table, Tabs } from "../../components/organisms";
import { TabsLabel } from "./enums";
import { generateTableHeader } from "./helpers/assignmentManagementHelpers";

const AssignmentManagementComponent = () => {
  const tabsData = [
    {
      id: 2,
      header: TabsLabel.PEOPLE,
    },
    {
      id: 1,
      header: TabsLabel.OFFICE,
    },
  ];

  const [assignmentTab, setAssignmentTab] = useState(tabsData[0]?.id);

  const generateTabsData = () => {
    return tabsData.map(({ id, header }) => ({
      id,
      header,
      content: (
        <Table cellsData={generateTableHeader(assignmentTab)} rowsData={[]} />
      ),
    }));
  };

  return (
    <Container>
      <Label>Assignment Management</Label>
      <Tabs
        data={generateTabsData()}
        onChangeTab={(assignmentId: number) => {
          setAssignmentTab(assignmentId);
        }}
      />
    </Container>
  );
};

export default AssignmentManagementComponent;
