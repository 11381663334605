import axios, { AxiosInstance } from "axios";
import EnvManager from "../config/envManager";
import { Equipment, EquipmentOffice } from "../store/model/interfaces";

export const inventoryApi: AxiosInstance = axios.create({
  baseURL: EnvManager.BACKEND_URL,
  // withCredentials: true, // TODO: uncomment this line
});

export const getCategories = async () => {
  try {
    const response = await inventoryApi.get("/categories");

    return response?.status === 200 ? response?.data : null;
  } catch (error) {
    // console.log("getUserPermissions error:", error);
  }
};

export const getEquipmentsByCategory = async (categoryId: number) => {
  try {
    const response = await inventoryApi.get(
      `/categories/${categoryId}/equipment`
    );

    return response?.status === 200 ? response?.data : null;
  } catch (error) {
    // console.log("getUserPermissions error:", error);
  }
};

export const getEquipmentInventory = async () => {
  try {
    const response = await inventoryApi.get(`/equipment/inventory`);

    return response?.status === 200 ? response?.data : null;
  } catch (error) {
    // console.log("getStatus error:", error);
  }
};

export const addEquipmentInventory = async (
  equipmentId: number,
  equipment: EquipmentOffice
) => {
  try {
    const response = await inventoryApi.put(
      `/equipment/${equipmentId}/inventory/add`,
      equipment
    );

    return response?.status === 200 ? response?.data : null;
  } catch (error) {
    // console.log("getUserPermissions error:", error);
  }
};

export const removeEquipmentInventory = async (
  equipmentId: number,
  equipment: EquipmentOffice
) => {
  try {
    const response = await inventoryApi.put(
      `/equipment/${equipmentId}/inventory/remove`,
      equipment
    );

    return response?.status === 200 ? response?.data : null;
  } catch (error) {
    // console.log("getUserPermissions error:", error);
  }
};

export const updateEquipmentByCategory = async (
  categoryId: number,
  equipmentId: number,
  equipment: Equipment
) => {
  try {
    const response = await inventoryApi.put(
      `/categories/${categoryId}/equipment/${equipmentId}`,
      equipment
    );

    return response?.status === 200 ? response?.data : null;
  } catch (error) {
    // console.log("getUserPermissions error:", error);
  }
};

export const createEquipmentByCategory = async (
  categoryId: number,
  equipment: Equipment
) => {
  try {
    const response = await inventoryApi.put(
      `/categories/${categoryId}/equipment`,
      equipment
    );

    return response?.status === 200 ? response?.data : null;
  } catch (error) {
    // console.log("getUserPermissions error:", error);
  }
};

export const getPeople = async () => {
  try {
    const response = await inventoryApi.get(`/people`);

    return response?.status === 200 ? response?.data : null;
  } catch (error) {
    // console.log("getUserPermissions error:", error);
  }
};

export const getOwners = async () => {
  try {
    const response = await inventoryApi.get(`/owners`);

    return response?.status === 200 ? response?.data : null;
  } catch (error) {
    // console.log("getUserPermissions error:", error);
  }
};

export const getStatus = async () => {
  try {
    const response = await inventoryApi.get(`/status`);

    return response?.status === 200 ? response?.data : null;
  } catch (error) {
    // console.log("getStatus error:", error);
  }
};
