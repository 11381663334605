import Button from "../../components/atoms/Button";
import Container from "../../components/atoms/Container";
import Label from "../../components/atoms/Label";
import EnvManager from "../../config/envManager";
import { APP_NAME } from "../../constants/appConstants";

const loginURL = `${EnvManager.AUTH_URL}authn/login/${APP_NAME}`;

function Login() {
  return (
    <Container>
      <Label sx={{ flex: "1 1 100%" }}>Inventory App</Label>
      <Button variant="contained" href={loginURL}>
        Login
      </Button>
    </Container>
  );
}

export default Login;
