import moment from "moment";
import { Input, Select } from "../../components/atoms";
import { GenericEquipmentInfoFormProps } from "./interfaces";
import { formatSelectOptionsForSubategories } from "./helpers/equipmentManagementHelpers";

const GenericEquipmentInfoForm = ({
  category,
  equipment,
  handleEquipmentChange,
  handleAttributeChange,
  handleSubcategoryChange,
}: GenericEquipmentInfoFormProps) => {
  return (
    <>
      <Input
        name="tag"
        label="Tag"
        type="string"
        value={equipment.tag}
        onChange={handleEquipmentChange}
      />
      <Input
        name="serial"
        label="Serial"
        type="string"
        value={equipment.serial}
        onChange={handleEquipmentChange}
      />
      <Select
        name="sub_category"
        label="Type"
        type="date"
        options={formatSelectOptionsForSubategories(category.sub_categories)}
        value={equipment.sub_category.id}
        onChange={handleSubcategoryChange}
      />
      <Input
        name="acquisition_date"
        label="Year"
        type="number"
        value={moment(equipment.acquisition_date).year().toString()}
        onChange={handleEquipmentChange}
      />
      {equipment.attributes.map((attribute_value, index) => {
        const { name, label, type } = attribute_value.attribute;
        const { label: unitLabel } = attribute_value.attribute_unit;

        return (
          <Input
            key={index}
            name={name}
            label={label}
            type={type}
            sufix={unitLabel || ""}
            value={attribute_value.value}
            onChange={handleAttributeChange}
          />
        );
      })}
      <Input
        name="acquisition_price"
        label="Price"
        type="number"
        sufix="USD"
        value={equipment.acquisition_price?.toString() || ""}
        onChange={handleEquipmentChange}
      />
      <Input
        name="worth"
        label="Worth"
        type="number"
        sufix="USD"
        value={equipment.worth.toString()}
        onChange={handleEquipmentChange}
      />
    </>
  );
};

export default GenericEquipmentInfoForm;
