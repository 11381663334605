import { action, computed, thunk } from "easy-peasy";
import { OfficeModel, OfficeBySeatName } from "./interfaces";

const office: OfficeModel = {
  data: null,
  addOffices: action((state, payload) => {
    state.data = payload;
  }),
  getOffices: thunk(async (actions, _, { injections }) => {
    const data = await injections.reservationsApi.getOffices();
    actions.addOffices(data);
  }),
  bySeatName: computed((state) => {
    const officeCopy = [...(state.data || [])];
    const bySeatName: OfficeBySeatName = {};
    officeCopy.map((office) => {
      const { seats } = office;
      seats.map((seat) => {
        bySeatName[seat.name] = office;
      });
    });

    return bySeatName;
  }),
};

export default office;
