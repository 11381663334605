import { Box, CircularProgress, Dialog } from "@mui/material";
import { useEffect, useState } from "react";
import { useStoreActions, useStoreState } from "../../../store";
import { List } from "../../../store/model/interfaces";
import { filterThunksByModel } from "./helpers";
import { styledBox, styledCircularProgress } from "./styles";
import { LoaderProps } from "./types";

const PreLoader = ({ properties, children }: LoaderProps) => {
  const { setIsLoad } = useStoreActions((actions) => actions.loader);
  const { list } = useStoreState((state) => state.loader);
  const handleClose = () => setIsLoad(false);
  const { addList } = useStoreActions((state) => state.loader);
  const [open, setOpen] = useState(true);
  const modelThunks = filterThunksByModel(properties);

  useEffect(() => {
    const newList: List[] = [];
    modelThunks.forEach((model) => {
      model.thunks?.forEach((thunk) => {
        newList.push({ name: model.name, thunk: thunk, isLoad: true });
      });
    });
    addList(newList);
  }, []);

  useEffect(() => {
    if (list.length) {
      const isFinished = !list.some((_thunk) => _thunk.isLoad);
      if (isFinished) {
        setOpen(false);
        addList([]);
      }
    }
  }, [list]);

  return (
    <Box>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <Box sx={styledBox}>
          <CircularProgress sx={styledCircularProgress} size="4rem" />
        </Box>
      </Dialog>
      {!open && children}
    </Box>
  );
};

export default PreLoader;
