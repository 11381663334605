import Typography from "@mui/material/Typography";
import { LabelProps } from "./interfaces";

const Label = ({ children, sx, centerText, color, ...rest }: LabelProps) => (
  <Typography
    sx={sx}
    color={color ? color : "inherit"}
    variant="subtitle1"
    {...(centerText ? { textAlign: "center" } : {})}
    {...rest}
  >
    {children}
  </Typography>
);

export default Label;
