import { action, computed, thunk } from "easy-peasy";
import { OwnerModel } from "./interfaces";

const owner: OwnerModel = {
  data: null,
  addOwners: action((state, payload) => {
    state.data = payload;
  }),
  getOwners: thunk(async (actions, _, { injections }) => {
    const data = await injections.api.getOwners();
    actions.addOwners(data);
  }),
  byName: computed((state) => {
    const ownerCopy = [...(state.data || [])];

    return ownerCopy.reduce((byName, owner) => {
      byName = { ...byName, [owner.name]: owner };

      return byName;
    }, {});
  }),
};

export default owner;
