import { Box } from "@mui/material";
import { TabPanelProps } from "./interfaces";

const TabPanel = ({ children, value, index, ...rest }: TabPanelProps) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...rest}
  >
    {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
  </div>
);

export default TabPanel;
