import Box from "@mui/material/Box";
import MUITab from "@mui/material/Tab";
import MUITabs from "@mui/material/Tabs";
import { SyntheticEvent, useState } from "react";
import { TabPanel } from "../../molecules";
import { styles } from "../../styles";
import { TabDataProps, TabProps } from "./interfaces";

/*
Ussage:
const tabsData = [
  {
    id: 1(category_id),
    header: "Computers"(category_name),
    content: <Label>Table component for computer</Label>(table to show equipment list),
  },
  {
    id: 2,
    header: "Periferic",
    content: "Table component",
  },
  {
    id: 3,
    header: "Office",
    content: "Table component",
  },
  {
    id: 4,
    header: "Others",
    content: "Table component",
  },
];

<Tabs
        data={tabsData}
        onChangeTab={(categoryId: number) => {
          console.log("get equipment " + categoryId);
        }}
      />

*/

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Tabs = ({ data, onChangeTab }: TabProps) => {
  const [value, setValue] = useState(0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={styles.fullWidth}>
      <Box sx={styles.borderBottom}>
        <MUITabs value={value} onChange={handleChange}>
          {data.map(({ id, header }: TabDataProps, index: number) => {
            return (
              <MUITab
                key={id}
                data-testid={`tab-component-${index}`}
                onClick={() => onChangeTab && onChangeTab(id)}
                label={header}
                {...a11yProps(index)}
              />
            );
          })}
        </MUITabs>
      </Box>
      {data.map(({ id, content }: TabDataProps, index: number) => {
        return (
          <TabPanel key={id} value={value} index={index}>
            {content}
          </TabPanel>
        );
      })}
    </Box>
  );
};

export default Tabs;
