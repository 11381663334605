import { faker } from "@faker-js/faker";
import {
  Category,
  Office,
  Equipment,
  Owner,
  People,
  Status,
  Seat,
  SubCategory,
  Attribute,
  AttributeUnit,
  EquipmentAttribute,
  EquipmentOffice,
} from "../interfaces";

const randomString = (length = 10): string => {
  let result = "";
  const characters = "abcdefghijklmnopqrstuvwxyz";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

const randomBoolean = (): boolean => {
  return !Math.round(Math.random());
};

export const equipmentFactory = ({
  id = faker.datatype.number(),
  category_id,
  sub_category = subCategoryFactory({ category_id }),
  attributes = [],
  created_at = faker.date.past().toUTCString(),
}: {
  id?: number;
  category_id: number;
  sub_category?: SubCategory;
  attributes?: EquipmentAttribute[];
  created_at?: string;
}): Equipment => {
  return {
    id,
    name: faker.random.word(),
    description: faker.random.words(),
    tag: faker.random.word(),
    serial: faker.random.word(),
    sub_category,
    status_id: faker.datatype.number(),
    owner_id: faker.datatype.number(),
    assignment_type_id: faker.datatype.number(),
    assignment_id: faker.random.alphaNumeric(),
    acquisition_price: faker.datatype.number(),
    acquisition_date: faker.date.past().toUTCString(),
    worth: faker.datatype.number(),
    location: faker.random.word(),
    provider: faker.random.word(),
    invoice_num: faker.random.word(),
    warranty_time: faker.random.word(),
    owner: null,
    attributes,
    status: null,
    created_at: created_at,
  };
};

export const categoryFactory = (
  categoryId: number,
  categoryName: string,
  label: string = faker.random.word(),
  sub_categories: SubCategory[] = [],
  attributes: Attribute[] = []
): Category => {
  return {
    id: categoryId,
    name: categoryName,
    label,
    attributes,
    sub_categories: sub_categories,
  };
};

export const subCategoryFactory = ({
  category_id,
  name = faker.random.word(),
  label = faker.random.word(),
}: {
  category_id: number;
  name?: string;
  label?: string;
}): SubCategory => {
  return {
    id: faker.datatype.number(),
    name,
    label,
    category_id,
  };
};

export const peopleFactory = (): People => {
  return {
    ioetId: faker.random.word(),
    ioetEmail: faker.internet.email(),
    name: faker.random.word(),
    lastName: faker.random.word(),
  };
};

export const ownerFactory = ({
  name = faker.random.word(),
}: {
  name?: string;
}): Owner => {
  return {
    id: faker.datatype.number(),
    name,
    label: faker.random.word(),
  };
};

export const statusFactory = ({
  name = faker.random.word(),
}: {
  name?: string;
}): Status => {
  return {
    id: faker.datatype.number(),
    name,
    label: faker.random.word(),
  };
};

const seatFactory = (
  seat_id: string = randomString(),
  name: string = randomString(),
  is_laptop: boolean = randomBoolean()
): Seat => {
  return { seat_id, name, is_laptop };
};

export const officeFactory = ({
  office_id = randomString(),
  name = randomString(),
  seats = [seatFactory(), seatFactory(), seatFactory(), seatFactory()],
}: {
  office_id?: string;
  name?: string;
  seats?: Seat[];
}): Office => {
  return {
    office_id,
    name,
    seats,
  };
};

export const officeList = (
  data: Office[] = [
    officeFactory({ office_id: "1", name: "loja" }),
    officeFactory({ office_id: "2", name: "quito" }),
  ]
) => {
  return data;
};

export const attributeFactory = ({
  name = faker.random.word(),
  label = faker.random.word(),
  type = "number",
}: {
  name?: string;
  label?: string;
  type?: "number" | "string";
}): Attribute => {
  return {
    id: faker.datatype.number(),
    name,
    label,
    type,
  };
};

export const attributeUnitFactory = (
  name: string = faker.random.word(),
  label: string = faker.random.word()
): AttributeUnit => {
  return {
    name,
    label,
  };
};

export const equipmentAttributeFactory = (
  attribute: Attribute = attributeFactory({}),
  attribute_unit: AttributeUnit = attributeUnitFactory(),
  value: string = faker.random.numeric()
): EquipmentAttribute => {
  return {
    attribute,
    value,
    attribute_unit,
  };
};

export const equipmentInventoryFactory = ({
  equipment_id = faker.datatype.number(),
  office_id = faker.random.alphaNumeric(),
  quantity = faker.datatype.number(),
  available = faker.datatype.number(),
  attribute_id = faker.datatype.number(),
}: {
  equipment_id: number;
  office_id?: string;
  quantity?: number;
  available?: number;
  attribute_id?: number;
}): EquipmentOffice => {
  return {
    equipment_id,
    office_id,
    quantity,
    available,
    attribute_id,
  };
};
