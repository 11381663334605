import { createTheme } from "@mui/material";

// Define theme settings
export const lightheme = createTheme({
  palette: {
    mode: "light",
  },
});

export const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});
