import { CategoryDataHead } from "./interfaces";

export const ID_TABLE_FIELD = { id: "tag", label: "Id" };
export const YEAR_TABLE_FIELD = { id: "acquisition_date", label: "Year" };

export const ASSIGNED_TO_TABLE_FIELD = {
  id: "assigned_to",
  label: "Assigned to",
};

export const PERIPHERAL_TABLE_FIELDS: CategoryDataHead[] = [
  { id: "quantity", label: "Total qty" },
  { id: "available", label: "Qty available" },
];
