import { action, computed, thunk } from "easy-peasy";
import { PeopleModel, People } from "./interfaces";

const people: PeopleModel = {
  data: [],
  addPeople: action((state, payload) => {
    state.data = payload;
  }),
  getPeople: thunk(async (actions, _, { injections }) => {
    const data = await injections.api.getPeople();
    const { edges } = data?.data?.employees || {};
    const people = edges?.map(({ node }: { node: People }) => node) || [];
    actions.addPeople(people);
  }),
  byEmail: computed((state) => {
    const peopleCopy = [...(state.data || [])];

    return peopleCopy.reduce((byEmail, people) => {
      byEmail = { ...byEmail, [people.ioetEmail]: people };

      return byEmail;
    }, {});
  }),
};

export default people;
