import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
} from "@mui/icons-material";
import { IconButton, Toolbar, Tooltip } from "@mui/material";
import { alpha } from "@mui/material/styles";
import Label from "../../atoms/Label";
import { TableToolbarProps } from "./interfaces";

const TableToolbar = ({ numSelected }: TableToolbarProps) => (
  <Toolbar
    sx={{
      pl: { sm: 2 },
      pr: { xs: 1, sm: 1 },
      ...(numSelected > 0 && {
        bgcolor: (theme) =>
          alpha(
            theme.palette.primary.main,
            theme.palette.action.activatedOpacity
          ),
      }),
    }}
  >
    {numSelected > 0 && <Label>{numSelected} selected</Label>}
    {numSelected > 0 ? (
      <Tooltip title="Delete">
        <IconButton>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    ) : (
      <Tooltip title="Filter list">
        <IconButton>
          <FilterListIcon />
        </IconButton>
      </Tooltip>
    )}
  </Toolbar>
);

export default TableToolbar;
