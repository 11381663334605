import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import RoutesDom from "./routes";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import { darkTheme, lightheme } from "./theme";
import { UserAPIContextProvider } from "./context/userAPIContext";
import { StoreProvider } from "easy-peasy";
import { store } from "./store";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const App = () => {
  // The light theme is used by default
  const [isDarkTheme, setIsDarkTheme] = useState(false);

  // This function is triggered when the Switch component is toggled
  const changeTheme = () => {
    setIsDarkTheme(!isDarkTheme);
  };

  return (
    <UserAPIContextProvider>
      <StoreProvider store={store}>
        <ThemeProvider theme={isDarkTheme ? darkTheme : lightheme}>
          <CssBaseline />
          <RoutesDom changeTheme={changeTheme} isDarkTheme={isDarkTheme} />
        </ThemeProvider>
      </StoreProvider>
    </UserAPIContextProvider>
  );
};

root.render(
  // https://reactjs.org/docs/strict-mode.html
  // Some of the checks and warnings that <React.StrictMode> provides include:

  // - Highlighting potential problems with legacy code or deprecated features.
  // - Warning you if you're using unsafe lifecycle methods.
  // - Identifying unexpected side effects or state changes that could cause bugs.
  // - Alerting you if you're making mutations to objects that are meant to be read-only.

  // If you're experiencing issues with multiple renders in development mode and <React.StrictMode>
  // is causing too much noise, you can consider temporarily disabling it while you debug your app.
  // Once you've identified and fixed any potential issues,
  // you can re-enable <React.StrictMode> to help catch any other problems that might arise.
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
