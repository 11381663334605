import { SelectChangeEvent } from "@mui/material";
import { ChangeEvent, useState } from "react";

import { Container } from "../../components/atoms";
import {
  formatInventoryDataToUpdate,
  handleAttributeChangeFn,
  handleEquipmentChangeFn,
  handleSubcategoryChangeFn,
  handleOfficeChangeFn,
  isPeripheral,
} from "./helpers/equipmentManagementHelpers";

import GenericEquipmentInfoForm from "./GenericEquipmentInfoForm";
import { EquipmentInformationComponentProps } from "./interfaces";
import PeripheralsInfoForm from "./PeripheralsInfoForm";
import { Office } from "../../store/model/interfaces";
import { InventoryAction } from "./enums";

const EquipmentInformationComponent = ({
  category,
  equipment,
  offices,
  inventoryData,
  setEquipment,
  addEquipmentInventory,
  removeEquipmentInventory,
}: EquipmentInformationComponentProps) => {
  const [officeSelected, setOfficeSelected] = useState<Office>(offices[0]);
  const [quantityToRemove, setQuantityToRemove] = useState<string>("");
  const [quantityToAdd, setQuantityToAdd] = useState<string>("");

  const handleSubcategoryChange = (
    event: SelectChangeEvent<number | string>
  ) => {
    handleSubcategoryChangeFn(event, equipment, category, setEquipment);
  };

  const handleAttributeChange = (event: ChangeEvent<HTMLInputElement>) => {
    handleAttributeChangeFn(event, equipment, setEquipment);
  };

  const handleEquipmentChange = (event: ChangeEvent<HTMLInputElement>) => {
    handleEquipmentChangeFn(event, equipment, setEquipment);
  };

  const handleOfficeChange = (event: SelectChangeEvent<number | string>) => {
    handleOfficeChangeFn(event, offices, setOfficeSelected);
  };

  const handleUpdateInventory = async (action: string) => {
    if (action === InventoryAction.ADD) {
      const dataToAdd = formatInventoryDataToUpdate(
        equipment,
        officeSelected,
        Number(quantityToAdd)
      );

      addEquipmentInventory(dataToAdd);
      setQuantityToAdd("");
    } else {
      const dataToRemove = formatInventoryDataToUpdate(
        equipment,
        officeSelected,
        Number(quantityToRemove)
      );

      removeEquipmentInventory(dataToRemove);
      setQuantityToRemove("");
    }
  };

  const handleInventoryChange = (
    event: ChangeEvent<HTMLInputElement>,
    action: string
  ) => {
    const value = event.target.value;

    const quantityToUpdate =
      action === InventoryAction.ADD ? setQuantityToAdd : setQuantityToRemove;
    quantityToUpdate(Number(value) > 0 ? value : "");
  };

  return (
    <Container>
      {isPeripheral(category.name) ? (
        <PeripheralsInfoForm
          equipment={equipment}
          offices={offices}
          officeSelected={officeSelected}
          handleOfficeChange={handleOfficeChange}
          handleUpdateInventory={handleUpdateInventory}
          handleInventoryChange={handleInventoryChange}
          quantityToRemove={quantityToRemove}
          quantityToAdd={quantityToAdd}
          inventoryData={inventoryData}
        />
      ) : (
        <GenericEquipmentInfoForm
          category={category}
          equipment={equipment}
          setEquipment={setEquipment}
          handleEquipmentChange={handleEquipmentChange}
          handleSubcategoryChange={handleSubcategoryChange}
          handleAttributeChange={handleAttributeChange}
        />
      )}
    </Container>
  );
};

export default EquipmentInformationComponent;
