export enum AssignmentPeople {
  FIRST_HEADER = "name",
  SECOND_HEADER = "computer",
}

export enum AssignmentOffice {
  FIRST_HEADER = "office",
  SECOND_HEADER = "seat",
}

export enum TabsLabel {
  PEOPLE = "people",
  OFFICE = "office",
}
