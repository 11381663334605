import {
  Box,
  Paper,
  TableContainer,
  TablePagination,
  Table as MUITable,
} from "@mui/material";
import { ChangeEvent, MouseEvent, useState } from "react";
import { TableBody, TableHead, TableToolbar } from "../../molecules";
import { styles } from "../../styles";
import { GenericTableData, TableProps } from "./interfaces";
import { Order } from "./types";

/*
Ussage
interface Data {
  id: number;
  calories: number;
  carbs: number;
  fat: number;
  name: string;
  protein: number;
  isAvailable: boolean;
}

function createData(
  id: number,
  name: string,
  calories: number,
  fat: number,
  carbs: number,
  protein: number,
  isAvailable: boolean
): Data {
  return {
    id,
    name,
    calories,
    fat,
    carbs,
    protein,
    isAvailable,
  };
}

const rowsD = [
  createData(1, "Cupcake", 305, 3.7, 67, 4.3, true),
  createData(2, "Donut", 452, 25.0, 51, 4.9, false),
  createData(3, "Eclair", 262, 16.0, 24, 6.0, false),
  createData(4, "Frozen yoghurt", 159, 6.0, 24, 4.0, true),
  createData(5, "Gingerbread", 356, 16.0, 49, 3.9, true),
  createData(6, "Honeycomb", 408, 3.2, 87, 6.5, true),
  createData(7, "Ice cream sandwich", 237, 9.0, 37, 4.3, true),
  createData(8, "Jelly Bean", 375, 0.0, 94, 0.0, false),
  createData(9, "KitKat", 518, 26.0, 65, 7.0, false),
  createData(10, "Lollipop", 392, 0.2, 98, 0.0, false),
  createData(11, "Marshmallow", 318, 0, 81, 2.0, false),
  createData(12, "Nougat", 360, 19.0, 9, 37.0, false),
  createData(13, "Oreo", 437, 18.0, 63, 4.0, false),
];

const headCellsD: readonly HeadCell[] = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Dessert (100g serving)",
    isFirst: true,
  },
  {
    id: "calories",
    numeric: true,
    disablePadding: false,
    label: "Calories",
  },
  {
    id: "fat",
    numeric: true,
    disablePadding: false,
    label: "Fat (g)",
  },
  {
    id: "carbs",
    numeric: true,
    disablePadding: false,
    label: "Carbs (g)",
  },
  {
    id: "protein",
    numeric: true,
    disablePadding: false,
    label: "Protein (g)",
  },
  {
    id: "isAvailable",
    numeric: true,
    disablePadding: false,
    label: "Is available",
    rowElement: (value) => (
      <input
        type="checkbox"
        id="scales"
        name="scales"
        checked={value as boolean}
      ></input>
    ),
  },
];

<Table
  allowSelection
  cellsData={headCellsD}
  rowsData={rowsD as unknown as GenericTableData[]}
/>;
*/

const Table = ({ allowSelection, rowsData, cellsData }: TableProps) => {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof GenericTableData>("");
  const [selected, setSelected] = useState<number[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: keyof GenericTableData
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rowsData.map((n) => n.id);
      setSelected(newSelected);

      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={styles.fullWidth}>
      <Paper sx={{ ...styles.fullWidth, mb: 2 }}>
        <TableToolbar numSelected={selected.length} />
        <TableContainer>
          <MUITable
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size="medium"
            data-testid="table-component"
          >
            <TableHead
              cellsData={cellsData}
              allowSelection={allowSelection}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy as string}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rowsData.length}
            />
            <TableBody
              allowSelection={allowSelection}
              rowsData={rowsData}
              cellsData={cellsData}
              order={order}
              orderBy={orderBy}
              selected={selected}
              page={page}
              rowsPerPage={rowsPerPage}
              setSelected={setSelected}
            />
          </MUITable>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rowsData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

export default Table;
