import moment from "moment";
import { SelectChangeEvent } from "@mui/material";
import { ChangeEvent } from "react";

import { SelectOption } from "../../../components/atoms/Select/interfaces";
import {
  Category,
  Equipment,
  EquipmentAttribute,
  EquipmentOffice,
  Office,
  SubCategory,
} from "../../../store/model/interfaces";
import { subCategoryFactory } from "../../../store/model/helpers/testDataHelpers";
import { ValueGenerator } from "../interfaces";
import { GenericTableData } from "../../../components/organisms/Table/interfaces";
import { EquipmentCategory, InventoryAction } from "../enums";
import { StockType } from "../types";

export const getEquipmentAttributeByName = (
  attributeName: string,
  equipmentAttributes: EquipmentAttribute[]
): EquipmentAttribute | undefined =>
  equipmentAttributes.find(
    (eqAttribute) => eqAttribute.attribute.name === attributeName
  );

const generateAttributeLabel = (
  attributeName: string,
  equipmentAttributes: EquipmentAttribute[]
) => {
  const equipmentAttribute = getEquipmentAttributeByName(
    attributeName,
    equipmentAttributes
  );

  return (
    equipmentAttribute?.value + " " + equipmentAttribute?.attribute_unit.label
  );
};

export const generateGenericData = ({
  id,
  tag,
  sub_category,
  acquisition_date,
  attributes,
}: Equipment) => {
  const genericData: GenericTableData = {
    id,
    tag,
    name: sub_category.label,
    acquisition_date: moment(acquisition_date).year(),
    assigned_to: "",
  };

  attributes.slice(0, 1).forEach(({ attribute, attribute_unit, value }) => {
    genericData[attribute.name] = value + " " + attribute_unit.label;
  });

  return genericData;
};

export const getAvailableQuantity = (
  equipmentAttributes: EquipmentAttribute[]
) => {
  const quantity = getEquipmentAttributeByName("quantity", equipmentAttributes);
  const assignedOffice = getEquipmentAttributeByName(
    "assigned_office",
    equipmentAttributes
  );
  const assignedPeople = getEquipmentAttributeByName(
    "assigned_people",
    equipmentAttributes
  );

  return (
    Number(quantity?.value) -
    (Number(assignedOffice?.value) + Number(assignedPeople?.value))
  );
};

export const generatePeripheralsData = ({
  id,
  sub_category,
  attributes,
}: Equipment) => {
  return {
    id,
    name: sub_category.label,
    quantity: generateAttributeLabel("quantity", attributes),
    available: getAvailableQuantity(attributes),
  };
};

export const formatSelectOptionsForSubategories = (
  subCategories: SubCategory[]
): SelectOption[] => {
  return subCategories.map(({ id, label }) => {
    return { id, label };
  });
};

export const handleSubcategoryChangeFn = (
  { target }: SelectChangeEvent<number | string>,
  equipmentData: Equipment,
  category: Category,
  setEquipmentData: (_temp: Equipment) => void
) => {
  const subCategoryId = Number(target.value);
  const temp = { ...equipmentData };
  const subCategory = category.sub_categories.find(
    (subCategory) => subCategory.id === subCategoryId
  );
  // create fake subcategory if subcategory is not fount (will never happen)
  temp.sub_category =
    subCategory || subCategoryFactory({ category_id: category.id });

  setEquipmentData(temp);
};

export const handleAttributeChangeFn = (
  { target: { name, value } }: ChangeEvent<HTMLInputElement>,
  equipmentData: Equipment,
  setEquipmentData: (_temp: Equipment) => void
) => {
  const temp = { ...equipmentData };
  const attributeIndex = temp.attributes.findIndex(
    (attribute) => attribute.attribute.name === name
  );
  temp.attributes[attributeIndex] = {
    ...temp.attributes[attributeIndex],
    value,
  };

  setEquipmentData(temp);
};

export const valuesGenerator: ValueGenerator = {
  acquisition_date: (value: string) => moment(value).format(),
};

export const handleEquipmentChangeFn = (
  { target: { name, value, type } }: ChangeEvent<HTMLInputElement>,
  equipmentData: Equipment,
  setEquipmentData: (_temp: Equipment) => void
) => {
  const valueGenerator = valuesGenerator[name];
  let generatedValue = type === "number" ? Number(value) : value;
  generatedValue = valueGenerator ? valueGenerator(value) : generatedValue;

  const temp = { ...equipmentData, [name]: generatedValue };

  setEquipmentData(temp);
};

export const isPeripheral = (categoryName: string) => {
  return categoryName === EquipmentCategory.PERIPHERALS;
};

export const formatOptionsForOffices = (offices: Office[]): SelectOption[] => {
  return offices.map(({ office_id, name }) => ({
    id: office_id,
    label: `${name.charAt(0).toUpperCase()}${name.slice(1)}`,
  }));
};

export const handleOfficeChangeFn = (
  { target }: SelectChangeEvent<number | string>,
  offices: Office[],
  setOfficeSelected: (_temp: Office) => void
) => {
  const officeId = target.value;
  const office = offices.filter(({ office_id }) => office_id === officeId);
  setOfficeSelected(office[0]);
};

export const getInventoryByOfficeAndEquipment = (
  inventoryData: EquipmentOffice[],
  officeSelected: Office,
  equipmentData: Equipment,
  stockType: StockType
) => {
  const result = inventoryData.find(
    ({ office_id, equipment_id }) =>
      office_id === officeSelected.office_id &&
      equipment_id === equipmentData.id
  );
  const indexing = stockType === "QTY" ? "quantity" : "available";

  return `${result?.[indexing] ?? "0"}`;
};

export const formatInventoryDataToUpdate = (
  equipment: Equipment,
  officeSelected: Office,
  quantity: number
) => {
  const equipmentData = {
    office_id: officeSelected.office_id,
    quantity,
    attribute_id: 1,
  };

  return {
    equipmentId: equipment.id,
    equipment: equipmentData,
  };
};

export const handleDisableInventoryBtn = (
  event: ChangeEvent<HTMLInputElement>,
  action: string,
  available: string,
  setIsDisabledBtn: (param: boolean) => void
) => {
  const isValueToDisable = () =>
    event.target.value === "0" || event.target.value === "";

  setIsDisabledBtn(
    action === InventoryAction.ADD
      ? isValueToDisable()
      : Number(event.target.value) > Number(available) || isValueToDisable()
  );
};
