import { ValidateValuelInRowIsUniqueProps } from "../interfaces";

export const validateValuelInRowIsUnique = ({
  allData,
  currentRow,
  value,
  indexCol,
}: ValidateValuelInRowIsUniqueProps) =>
  !allData.filter(
    (row: string[], index) =>
      currentRow != index && (row[indexCol] as string) === value
  ).length;
