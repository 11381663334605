import { createTypedHooks } from "easy-peasy";
import { StoreModel } from "./model/interfaces";

const { useStoreActions, useStoreDispatch, useStoreState } =
  createTypedHooks<StoreModel>();

// We export the hooks from our store as they will contain the
// type information on them
export { useStoreActions, useStoreDispatch, useStoreState };

export { default as store } from "./model";
