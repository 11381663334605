import { ChangeEvent, useState, useEffect } from "react";
import {
  Info as InfoIcon,
  FileUpload as FileUploadIcon,
} from "@mui/icons-material";

import { Box } from "@mui/material";
import { ErrorListData, ImportFileProps } from "./interfaces";
import { readFile, getUrlTemplate } from "./helpers";
import ImportFileDialog from "./importFileDialog";
import { Button, Container } from "../../atoms";

const ImportFile = ({
  category,
  categoryBySubcategoryName,
  officeBySeatName,
  ownerByName,
  peopleByEmail,
  statusByName,
}: ImportFileProps) => {
  const [open, setOpen] = useState(false);
  const [generalErrors, setGeneralErrors] = useState<string[]>([]);
  const [errors, setErrors] = useState<ErrorListData[]>([]);
  const [isValid, setIsValid] = useState<boolean | null>(null);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [, setFileContent] = useState<string | null>(null);
  const [urlTemplate, setUrlTemplate] = useState<string>();
  const { name } = category;
  useEffect(() => {
    setUrlTemplate(getUrlTemplate(category));
  }, [category]);

  const handleFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      readFile({
        setIsValid,
        setGeneralErrors,
        setErrors,
        setFileContent,
        setButtonDisabled,
        category,
        categoryBySubcategoryName,
        file,
        officeBySeatName,
        ownerByName,
        peopleByEmail,
        statusByName,
      });
    }
  };

  const onHandleClose = () => {
    setOpen(false);
    setIsValid(null);
    setGeneralErrors([]);
    setErrors([]);
  };

  return (
    <Container>
      <Box display="flex" justifyContent="end" width="100%" padding={3}>
        {name !== "peripherals" && name !== "others" ? (
          <Button
            variant="contained"
            onClick={() => setOpen(true)}
            endIcon={<FileUploadIcon />}
          >
            IMPORT
          </Button>
        ) : null}
        {urlTemplate && (
          <Button
            title="go to sheet template"
            href={urlTemplate}
            target="_blank"
            endIcon={<InfoIcon />}
          >
            Help
          </Button>
        )}
      </Box>
      <ImportFileDialog
        open={open}
        isValid={isValid}
        handleClose={() => onHandleClose()}
        handleFileSelect={handleFileSelect}
        buttonSubmitDisabled={buttonDisabled}
        generalErrors={generalErrors}
        errors={errors}
      />
    </Container>
  );
};

export default ImportFile;
