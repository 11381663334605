import { useEffect } from "react";
import { PreLoader } from "../../components/organisms";
import { Label } from "../../components/atoms";
import { useStoreActions, useStoreState } from "../../store";
import EquipmentManagementComponent from "./EquipmentManagementComponent";

const EquipmentManagementContainer = () => {
  const { getCategories } = useStoreActions((actions) => actions.category);
  const { getOwners } = useStoreActions((actions) => actions.owner);
  const { getPeople } = useStoreActions((actions) => actions.people);
  const { getStatus } = useStoreActions((actions) => actions.status);
  const { getOffices } = useStoreActions((actions) => actions.office);
  const {
    addEquipmentInventory,
    getEquipmentsByCategory,
    getEquipmentInventory,
    removeEquipmentInventory,
  } = useStoreActions((actions) => actions.equipment);
  const {
    data: categories,
    byId: categoriesById,
    bySubcategoryName: categoryBySubcategoryName,
  } = useStoreState((state) => state.category);
  const {
    data: equipment,
    inventoryData,
    byId: equipmentById,
  } = useStoreState((state) => state.equipment);
  const { data: officeList, bySeatName: officeBySeatName } = useStoreState(
    (state) => state.office
  );
  const { byName: ownerByName } = useStoreState((state) => state.owner);
  const { byName: statusByName } = useStoreState((state) => state.status);
  const { byEmail: peopleByEmail } = useStoreState((state) => state.people);

  useEffect(() => {
    getCategories();
    getPeople();
    getOwners();
    getStatus();
    getOffices();
    getEquipmentInventory();
  }, []);

  return (
    <PreLoader
      properties={[
        "getCategories",
        "getPeople",
        "getOwners",
        "getStatus",
        "getOffices",
      ]}
    >
      <>
        {!!categories?.length && Object.keys(categoriesById).length && (
          <EquipmentManagementComponent
            categories={categories || []}
            categoryBySubcategoryName={categoryBySubcategoryName}
            categoriesById={categoriesById}
            equipment={equipment || []}
            ownerByName={ownerByName}
            statusByName={statusByName}
            peopleByEmail={peopleByEmail}
            officeBySeatName={officeBySeatName}
            equipmentById={equipmentById}
            onCategoryChange={(categoryId: number) =>
              getEquipmentsByCategory(categoryId)
            }
            officeList={officeList || []}
            addEquipmentInventory={addEquipmentInventory}
            removeEquipmentInventory={removeEquipmentInventory}
            inventoryData={inventoryData || []}
          />
        )}
        {!categories?.length && (
          <span>
            <Label centerText>There are no categories to show.!!</Label>
          </span>
        )}
      </>
    </PreLoader>
  );
};

export default EquipmentManagementContainer;
