import { Container, Label } from "../../components/atoms";

const Home = () => {
  return (
    <Container>
      <Label>Home</Label>
      <h2>Inventory App</h2>
    </Container>
  );
};

export default Home;
