import { useEffect } from "react";
import { PreLoader } from "../../components/organisms";
import { useStoreActions } from "../../store";
import AssignmentManagementComponent from "./AssignmentManagementComponent";

const AssignmentManagementContainer = () => {
  const { getPeople } = useStoreActions((actions) => actions.people);

  useEffect(() => {
    getPeople();
  }, []);

  return (
    <PreLoader properties={["getPeople"]}>
      <>
        <AssignmentManagementComponent />
      </>
    </PreLoader>
  );
};

export default AssignmentManagementContainer;
