import { InputAdornment, TextField } from "@mui/material";
import { InputProps } from "./interfaces";

const Input = ({
  name,
  label,
  type,
  sufix,
  value,
  disabled,
  onChange,
}: InputProps) => (
  <TextField
    fullWidth
    sx={{ m: 1, ml: 0, mr: 0 }}
    id={`input-${name}`}
    name={name}
    label={label}
    type={type}
    value={value}
    onChange={onChange}
    disabled={disabled}
    InputProps={{
      endAdornment: sufix ? (
        <InputAdornment position="end">{sufix}</InputAdornment>
      ) : null,
      inputProps: {
        min: 0,
      },
    }}
  />
);

export default Input;
