import { Alert as MUIAalert } from "@mui/material";
import AlertTitle from "@mui/material/AlertTitle";
import Stack from "@mui/material/Stack";

import { AlertProps } from "./interfaces";

const Alert = ({ children, severity, variant, title }: AlertProps) => (
  <Stack sx={{ width: "100%" }} spacing={2}>
    <MUIAalert variant={variant} severity={severity}>
      <AlertTitle>{title}</AlertTitle>
      {children}
    </MUIAalert>
  </Stack>
);

export default Alert;
