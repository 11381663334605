import { Box, CircularProgress, Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { useStoreActions, useStoreState } from "../../../store";
import { List } from "../../../store/model/interfaces";
import { filterThunksByModel } from "./helpers";
import { styledPaper } from "./styles";
import { LoaderProps } from "./types";

const Loader = ({ thunkActions, loading }: LoaderProps) => {
  const { list } = useStoreState((state) => state.loader);
  const { addList } = useStoreActions((state) => state.loader);
  const [open, setOpen] = useState(true);
  const modelThunks = filterThunksByModel(thunkActions ?? []);

  useEffect(() => {
    const newList: List[] = [];
    modelThunks.forEach((model) => {
      model.thunks?.forEach((thunk) => {
        newList.push({ name: model.name, thunk: thunk, isLoad: true });
      });
    });
    addList(newList);
  }, []);

  useEffect(() => {
    if (Boolean(loading)) {
      setOpen(Boolean(loading));

      return;
    }

    if (list.length) {
      const isFinished = !list.some((_thunk) => _thunk.isLoad);
      if (isFinished) {
        setOpen(false);
        addList([]);
      }
    }
  }, [list, loading]);

  return (
    <Box width="100%" height="100%">
      {open && (
        <Paper elevation={0} sx={{ styledPaper }}>
          <CircularProgress size="4rem" data-testid="circular-progress" />
        </Paper>
      )}
    </Box>
  );
};

export default Loader;
