import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { useUserAPI } from "../context/userAPIContext";
import Login from "../pages/Login";
import Home from "../pages/Home";
import { NavBarContainer } from "../components/organisms";
import EquipmentManagement from "../pages/EquipmentManagement";
import AssignmentManagement from "../pages/AssignmentManagement";
import { RoutesDomProps, RouteProps } from "./interfaces";

const RoutesDom = ({ changeTheme, isDarkTheme }: RoutesDomProps) => {
  const { user } = useUserAPI();

  const ProtectedRoute = ({ component }: RouteProps) => {
    if (!user) {
      return <Navigate to="/login" replace />;
    }

    return component;
  };

  const LoginRoute = () => {
    if (!user) {
      return <Login />;
    }

    return <Navigate to="/" replace />;
  };

  const routes: RouteProps[] = [
    {
      path: "login",
      component: <LoginRoute />,
    },
    {
      withAuth: false, // TODO: restore to True
      path: "",
      component: <Home />,
    },
    {
      withAuth: false, // TODO: restore to True
      path: "equipment_management",
      component: <EquipmentManagement />,
    },
    {
      withAuth: false, // TODO: restore to True
      path: "assignment_management",
      component: <AssignmentManagement />,
    },
    {
      path: "*",
      component: <p>There's nothing here: 404!</p>,
    },
  ];

  const generateRoute = (
    { withAuth, component, ...rest }: RouteProps,
    index: number
  ) => {
    const element = withAuth ? (
      <ProtectedRoute component={component} />
    ) : (
      component
    );

    return <Route key={index} element={element} {...rest} />;
  };

  return (
    <BrowserRouter>
      {/*
        TODO: add removed validation for the navbar when login is enabled again
      */}
      <NavBarContainer changeTheme={changeTheme} isDarkTheme={isDarkTheme} />
      <Routes>{routes.map(generateRoute)}</Routes>
    </BrowserRouter>
  );
};

export default RoutesDom;
