import MUIDialog from "@mui/material/Dialog";
import MUIDialogActions from "@mui/material/DialogActions";
import MUIDialogContent from "@mui/material/DialogContent";
import MUIDialogTitle from "@mui/material/DialogTitle";

import Button from "../../atoms/Button";
import { DialogProps } from "./types";

const Dialog = ({
  buttonLabel,
  children,
  handleClose,
  handleSave,
  type,
  title,
  open,
  maxWidth,
}: DialogProps) => {
  return (
    <MUIDialog
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={maxWidth ? maxWidth : "xs"}
    >
      {title && <MUIDialogTitle>{title}</MUIDialogTitle>}
      <MUIDialogContent>{children}</MUIDialogContent>
      <MUIDialogActions>
        <Button small onClick={handleClose}>
          {type === "Confirm" ? "Cancel" : "Ok"}
        </Button>
        {type === "Confirm" && (
          <Button small onClick={handleSave}>
            {buttonLabel || "Save"}
          </Button>
        )}
      </MUIDialogActions>
    </MUIDialog>
  );
};

export default Dialog;
