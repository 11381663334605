import NavBarComponent from "./NavBarComponent";
import { NavBarContainerProps } from "./interfaces";

const NavBarContainer = ({
  changeTheme,
  isDarkTheme,
}: NavBarContainerProps) => {
  return (
    <NavBarComponent changeTheme={changeTheme} isDarkTheme={isDarkTheme} />
  );
};

export default NavBarContainer;
