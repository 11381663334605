import { createContext, useContext, useEffect, useState } from "react";
import { getUserPermissions } from "../services/authorization";
import { ContextValues, ProviderProps } from "./interfaces";

const contextReturnValues: ContextValues = {
  user: undefined,
  checkUserAuthentication: () => Promise.resolve(),
};

const UserAPIContext = createContext(contextReturnValues);

export const UserAPIContextProvider = ({ children }: ProviderProps) => {
  const [user, setUser] = useState();

  const fetchAndCheckUserPermissions = async () => {
    const userData = await getUserPermissions();
    if (userData) {
      setUser(userData);
    } else {
      setUser(undefined);
    }
  };

  useEffect(() => {
    fetchAndCheckUserPermissions().catch(() => {
      // console.log(error);
    });
  }, []);

  return (
    <UserAPIContext.Provider
      value={{
        user,
        checkUserAuthentication: fetchAndCheckUserPermissions,
      }}
    >
      {children}
    </UserAPIContext.Provider>
  );
};

export const useUserAPI = () => {
  const context = useContext(UserAPIContext);
  if (context == null) {
    throw new Error("Context must be used within a Provider");
  }

  return context;
};
