import { PageListProps, SettingListProps } from "./interfaces";

export const pages: PageListProps[] = [
  {
    label: "Equipments",
    name: "equipments",
    link: "equipment_management",
  },
  {
    label: "Assignment",
    name: "assignment",
    link: "assignment_management",
  },
];

export const settings: SettingListProps[] = [{ label: "Logout", id: "logout" }];
