import { action, thunkOn } from "easy-peasy";
import { LoaderModel } from "./interfaces";
import { getThunkActions } from "../../components/organisms/PreLoader/helpers";

const loader: LoaderModel = {
  list: [],
  isLoad: true,
  setIsLoad: action((state, payload) => {
    state.isLoad = payload;
  }),
  addList: action((state, payload) => {
    state.list = payload;
  }),
  updateList: action((state, payload) => {
    const result = state.list.map((_thunk) => {
      if (_thunk.name === payload.name) {
        return { name: payload.name, thunk: payload.name, isLoad: false };
      }

      return _thunk;
    });

    state.list = result;
  }),
  onThunk: thunkOn(
    (_actions, storeActions) => getThunkActions(storeActions),
    (actions, target) => {
      const [, storeName, fullActionName] = target.type.split(".");
      const [actionName] = fullActionName.split("(");
      const status = target.type.includes("success");
      actions.updateList({
        name: storeName,
        thunk: actionName,
        isLoad: status,
      });
    }
  ),
};

export default loader;
