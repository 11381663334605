import { Category } from "../../../../store/model/interfaces";
import { CategoryNames } from "../../../../enums/categoryEnums";
import {
  pathComputersTemplate,
  pathMonitorsTemplate,
  pathPhonesTemplate,
} from "../constants";

export const getUrlTemplate = ({ name }: Category) => {
  let url = "";
  if (name === CategoryNames.computers) url = pathComputersTemplate;
  else if (name === CategoryNames.monitors) url = pathMonitorsTemplate;
  else if (name === CategoryNames.phones) url = pathPhonesTemplate;

  return url;
};

export default getUrlTemplate;
