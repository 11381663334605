export const styledBox = {
  display: "flex",
  justifyContent: "center",
  p: 2,
  m: 2,
  backgroundColor: "transparent",
  boxShadow: "none",
  borderRadius: 1,
};

export const styledCircularProgress = {
  color: "white",
};
