import { action, computed, thunk } from "easy-peasy";
import { StatusModel } from "./interfaces";

const status: StatusModel = {
  data: null,
  addStatus: action((state, payload) => {
    state.data = payload;
  }),
  getStatus: thunk(async (actions, _, { injections }) => {
    const data = await injections.api.getStatus();
    actions.addStatus(data);
  }),
  byName: computed((state) => {
    const statusCopy = [...(state.data || [])];

    return statusCopy.reduce((byName, status) => {
      byName = { ...byName, [status.name]: status };

      return byName;
    }, {});
  }),
};

export default status;
