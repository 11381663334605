import { parse } from "papaparse";
import { Category } from "../../../../store/model/interfaces";
import { getAllColumnsCSV } from ".";
import { vadilateDATA } from "./vadilateDataHelpers";
import { ErrorListData, ErrorData, ValidateProps } from "../interfaces";

export const validateTemplateCSV = (payload: string) => {
  return new Promise(async (resolve, reject) => {
    const data = parse(payload, {
      header: true,
      download: false,
      skipEmptyLines: true,
      delimiter: ",",
    });

    if (data !== null || typeof data !== "undefined") {
      resolve(data);
    } else {
      reject(Error("ERROR: Data is incorrect."));
    }
  });
};

export const getAllData = (data: [{ [key: string]: string }]) => {
  const result: string[][] = [];
  for (let f = 1; f < data.length; f++) {
    const obj = data[f];
    const row = [];
    for (const key in obj) {
      row.push(obj[key]);
    }
    result.push(row);
  }

  return result;
};

export const validate = async ({
  fileContent,
  officeBySeatName,
  category,
  categoryBySubcategoryName,
  ownerByName,
  peopleByEmail,
  statusByName,
}: ValidateProps) => {
  const { data } = (await validateTemplateCSV(fileContent)) as {
    data: [{ [key: string]: string }];
  };
  const errorListData: ErrorListData[] = [];
  for (let f = 1; f < data.length; f++) {
    const objError: ErrorData[] = [];
    let tag = "";
    const obj: { [key: string]: string } = data[f] as {
      [key: string]: string;
    };
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        const error = vadilateDATA({
          currentRow: f - 1,
          officeBySeatName,
          header: key,
          category,
          categoryBySubcategoryName,
          data: value,
          allData: getAllData(data),
          ownerByName,
          peopleByEmail,
          statusByName,
        });
        if (key === "tag") tag = value;
        if (Boolean(error.message)) objError.push(error);
      }
    }
    if (Boolean(Object.keys(objError))) {
      errorListData.push({ tag: tag, errors: objError });
    }
  }
  const errors = errorListData;

  return errors;
};

export const validateCSVColumns = (
  payload: string,
  category: Category,
  setErrors: (_errors: string[]) => void
) => {
  validateTemplateCSV(payload).then(async (res) => {
    const preview = res as unknown as { meta: { fields: string[] } };
    const { fields } = preview.meta;
    const errors: string[] = [];
    const columnsCSV = getAllColumnsCSV(category);
    const isEqual =
      fields.length === columnsCSV.length &&
      fields.every(function (v, i) {
        return v === columnsCSV[i];
      });
    if (!isEqual) {
      errors.push(
        "Uploaded csv does not match the columns of the template, please check the columns and upload it again."
      );
    }
    setErrors(errors);
  });
};
