import {
  VadilateDataProps,
  ValidateExistsOfficeProps,
  ValidateExistsOwnerProps,
  ValidateExistsPeopleProps,
  ValidateExistsSubCategoryProps,
  ValidateExistsStateProps,
} from "../interfaces";
import { Attribute } from "../../../../store/model/interfaces";
import { validateValuelInRowIsUnique } from "./";

const isNull = (data: unknown) =>
  data === null && data === undefined && data === "";

const isAlphanumeric = (data: unknown) =>
  typeof data === "string" && /^[a-zA-Z0-9-\s]+$/.test(data);

const isString = (value: unknown): boolean => {
  return typeof value === "string";
};

const isNumber = (value: unknown) => {
  if (typeof value === "string") {
    const regex = /^[0-9]*\.?[0-9]+$/;

    return regex.test(value) && !isNaN(parseFloat(value));
  } else return false;
};

const validateFormat = (regex: RegExp, data: unknown): boolean => {
  return typeof data === "string" && !regex.test(data);
};
export const reduceNames = (list: { name: string }[]) => {
  return list
    .reduce((res, { name }) => `${res}, ${name}`, ":")
    .replaceAll(":, ", "")
    .replaceAll(":", "");
};

export const validateExistsOwner = ({
  value,
  ownerByName,
}: ValidateExistsOwnerProps) => !!ownerByName[value];

export const validateExistsSubCategory = ({
  value,
  categoryBySubcategoryName,
}: ValidateExistsSubCategoryProps) => !!categoryBySubcategoryName[value];

export const validateExistsState = ({
  value,
  statusByName,
}: ValidateExistsStateProps) => !!statusByName[value];

export const validateExistsPeople = ({
  value,
  peopleByEmail,
}: ValidateExistsPeopleProps) => !!peopleByEmail[value];

export const validateExistsOffice = ({
  value,
  officeBySeatName,
}: ValidateExistsOfficeProps) => !!officeBySeatName[value];

export const vadilateDATA = ({
  currentRow,
  header,
  category,
  categoryBySubcategoryName,
  officeBySeatName,
  data,
  allData,
  ownerByName,
  peopleByEmail,
  statusByName,
}: VadilateDataProps) => {
  let message: string | null = null;

  const { attributes } = category;
  if (header === "owner") {
    if (validateFormat(/^ioet-(inc|sas)$/, data)) {
      message = `${header}: only accepted "ioet INC" or "ioet SAS"`;
    }
    if (!message && !validateExistsOwner({ value: data, ownerByName })) {
      message = `${header}: the value owner = '${data}' is not accepted`;
    }
  }
  if (header === "tag") {
    if (validateFormat(/^ec\d+/, data)) {
      message = `${header}: only accepted a string with the next format ec (in lower case) followed by numbers, for example ec1234`;
    }
    if (
      !message &&
      !validateValuelInRowIsUnique({
        value: data,
        allData,
        currentRow,
        indexCol: 1,
      })
    ) {
      message = `${header}: '${data}', must be unique `;
    }
  }
  if (header === "serial") {
    if (!isAlphanumeric(data) || isNull(data)) {
      message = `${header}: accepts only alphanumeric`;
    }
    if (
      !message &&
      !validateValuelInRowIsUnique({
        value: data,
        allData,
        currentRow,
        indexCol: 2,
      })
    ) {
      message = `${header}: '${data}', must be unique `;
    }
  }
  if (header === "type") {
    if (!isAlphanumeric(data) || isNull(data)) {
      message = `${header}: accepts only alphanumeric`;
    }
    if (
      !message &&
      !validateExistsSubCategory({ value: data, categoryBySubcategoryName })
    ) {
      message = `${header}: the value type = '${data}' is not accepted`;
    }
  }
  if (header === "model") {
    if (!isAlphanumeric(data) && isNull(data)) {
      message = `${header}: accepts only alphanumeric`;
    }
  }
  if (header === "state") {
    if (!isAlphanumeric(data)) {
      message = `${header}: accepts only alphanumeric`;
    }
    if (!message && !validateExistsState({ value: data, statusByName })) {
      message = `${header}: ': the value ${header} = ${data}' is not accepted`;
    }
  }
  if (header === "location") {
    const regex =
      /(Quito|Loja|Guayaquil|Argentina|Honduras|República Dominicana)/;
    if ((isAlphanumeric(data) && isNull(data), validateFormat(regex, data))) {
      message = `${header}: accepts only Quito, Loja ,Guayaquil, Argentina, Honduras, República Dominicana`;
    }
  }
  if (header === "acquisition_date") {
    if (isString(data)) {
      const isDate = /^\d{4}-\d{2}-\d{2}$/.test(data as string);
      const isValidDate = isDate && !isNaN(Date.parse(data as string));
      if (!isValidDate) {
        message = `${header} accepts only date format (yyyy-mm-dd)`;
      }
    } else {
      message = `${header} accepts only date format (yyyy-mm-dd)`;
    }
  }
  if (header === "acquisition_price") {
    if (!isNumber(data)) {
      message = `${header}: accepts only numbers`;
    }
  }
  if (header === "current_worth") {
    if (!isNumber(data)) {
      message = `${header} accepts only numbers`;
    }
  }
  if (header === "provider") {
    if (!isAlphanumeric(data)) {
      message = `${header}: accepts only alphanumeric`;
    }
  }
  if (header === "invoice_num") {
    if (!isAlphanumeric(data)) {
      message = `${header}: accepts only alphanumeric`;
    }
  }
  if (header === "warranty_time") {
    if (!isAlphanumeric(data)) {
      message = `${header}: accepts only alphanumeric`;
    }
  }
  if (header === "notes") {
    if (!isAlphanumeric(data)) {
      message = `${header}: accepts only alphanumeric`;
    }
  }

  if (header === "current_user" && data?.length) {
    if (!validateExistsPeople({ value: data, peopleByEmail })) {
      message = `${header}: '${data}', not exist in People Data`;
    }
  }

  if (header === "seat" && data?.length) {
    if (!validateExistsOffice({ value: data, officeBySeatName })) {
      message = `${header}: '${data}', not exist in Office Data`;
    }
  }
  attributes.forEach(({ name, type }: Attribute) => {
    if (header === name) {
      if (type === "number") {
        if (!isNumber(data)) {
          message = `${header}: value = '${data}' is incorrect. It accepts only numbers`;
        }
      }
      if (type === "string") {
        if (!isString(data)) {
          message = `${header}: accepts only text`;
        }
      }
    }
  });

  // current-user: email, if empty then seat has a value
  // seat: loja-a1 quito-a1 quito-a2, if the set is empty then surrent has a value of

  return {
    message: message,
    header: header,
    value: data,
  };
};
