import { action, computed, thunk } from "easy-peasy";
import { EquipmentModel } from "./interfaces";

const equipment: EquipmentModel = {
  data: null,
  inventoryData: null,
  addEquipments: action((state, payload) => {
    state.data = payload;
  }),
  addInventoryData: action((state, payload) => {
    state.inventoryData = payload;
  }),
  updateEquipment: action((state, payload) => {
    const equipmentsCopy = [...(state.data || [])];

    const equipementIndex = equipmentsCopy.findIndex(
      (equipement) => equipement.id === payload.id
    );
    equipmentsCopy[equipementIndex] = payload;
    state.data = equipmentsCopy;
  }),
  byId: computed((state) => {
    const equipmentsCopy = [...(state.data || [])];

    return equipmentsCopy.reduce((byId, equipment) => {
      byId = equipment.id ? { ...byId, [equipment.id]: equipment } : byId;

      return byId;
    }, {});
  }),
  updateInventoryData: action((state, payload) => {
    const inventoryDataCopy = [...(state.inventoryData || [])];
    const equipementIndex = inventoryDataCopy.findIndex(
      ({ equipment_id, office_id }) =>
        equipment_id === payload.equipment_id && office_id === payload.office_id
    );

    if (equipementIndex >= 0) {
      inventoryDataCopy[equipementIndex] = payload;
    } else {
      inventoryDataCopy.push(payload);
    }

    state.inventoryData = inventoryDataCopy;
  }),
  updateEquipmentByCategoryId: thunk(
    async (actions, { categoryId, equipmentId, equipment }, { injections }) => {
      const data = await injections.api.updateEquipmentByCategory(
        categoryId,
        equipmentId,
        equipment
      );
      actions.updateEquipment(data);
    }
  ),
  addEquipmentInventory: thunk(
    async (actions, { equipmentId, equipment }, { injections }) => {
      const data = await injections.api.addEquipmentInventory(
        equipmentId,
        equipment
      );
      actions.updateInventoryData(data);
    }
  ),
  removeEquipmentInventory: thunk(
    async (actions, { equipmentId, equipment }, { injections }) => {
      const data = await injections.api.removeEquipmentInventory(
        equipmentId,
        equipment
      );
      actions.updateInventoryData(data);
    }
  ),
  createEquipmentByCategoryId: thunk(
    async (actions, { categoryId, equipment }, { injections }) => {
      const data = await injections.api.createEquipmentByCategory(
        categoryId,
        equipment
      );
      actions.updateEquipment(data);
    }
  ),
  getEquipmentsByCategory: thunk(
    async (actions, categoryId, { injections }) => {
      const data = await injections.api.getEquipmentsByCategory(categoryId);
      actions.addEquipments(data);
    }
  ),
  getEquipmentInventory: thunk(async (actions, _, { injections }) => {
    const data = await injections.api.getEquipmentInventory();
    actions.addInventoryData(data);
  }),
};

export default equipment;
