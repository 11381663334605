import { useState, MouseEvent } from "react";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Container,
  Tooltip,
} from "@mui/material";

import AdbIcon from "@mui/icons-material/Adb";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useUserAPI } from "../../../context/userAPIContext";
import { logoutUser } from "../../../services/authorization";
import { NavBarContainerProps } from "./interfaces";
import { pages, settings } from "./constants";
import Button from "../../atoms/Button";
import PagesMenu from "../../molecules/PagesMenu";
import SettingsMenu from "../../molecules/SettingsMenu";

const NavBarComponent = ({
  changeTheme,
  isDarkTheme,
}: NavBarContainerProps) => {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const { checkUserAuthentication } = useUserAPI();
  const navigate = useNavigate();

  const handleLogout = async () => {
    await logoutUser();
    await checkUserAuthentication();
    navigate("/login");
  };

  const handleCloseUserMenu = (id?: string) => {
    setAnchorElUser(null);
    if (id && id === "logout") handleLogout();
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={() => {
              handleCloseNavMenu();
              navigate("/");
            }}
            color="inherit"
            sx={{ display: { xs: "none", md: "flex" } }}
          >
            <AdbIcon />
          </IconButton>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <PagesMenu
              anchorEl={anchorElNav}
              onClose={handleCloseNavMenu}
              onClickMenuItem={() => handleCloseNavMenu()}
              pages={pages}
              open={Boolean(anchorElNav)}
            />
          </Box>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={() => {
              handleCloseNavMenu();
              navigate("/");
            }}
            color="inherit"
            sx={{ display: { xs: "flex", md: "none" } }}
          >
            <AdbIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map(({ name, label, link }) => (
              <Button
                key={name}
                onClick={() => {
                  handleCloseNavMenu();
                  navigate(link);
                }}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                {label}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open user options">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <AccountCircleIcon />
              </IconButton>
            </Tooltip>
            <SettingsMenu
              anchorEl={anchorElUser}
              open={Boolean(anchorElUser)}
              onClose={() => handleCloseUserMenu()}
              onClickMenu={handleCloseUserMenu}
              changeTheme={changeTheme}
              isDarkTheme={isDarkTheme}
              settings={settings}
            />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default NavBarComponent;
