import { storeModel } from "../../../../store/model";
import { thunkActions } from "../types";

const filterThunksByModel = (properties: thunkActions) => {
  const mappedInterfaceOptions = Object.entries(storeModel).map((model) => {
    const key = model as unknown as keyof typeof storeModel;

    return key;
  });

  const response = mappedInterfaceOptions
    .map((model) => {
      const resut = Object.keys(model[1]).filter((option) =>
        properties.toString().includes(option)
      );

      if (resut.length) return { name: model[0], thunks: resut };

      return {};
    })
    .filter((val) => val?.thunks);

  return response;
};

export default filterThunksByModel;
