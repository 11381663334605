import { Link } from "@mui/material";
import { GenericTableData } from "../../../components/organisms/Table/interfaces";
import {
  CategoryDataHeadByCategoryName,
  HeadByCategoryId,
} from "../interfaces";

import {
  ASSIGNED_TO_TABLE_FIELD,
  ID_TABLE_FIELD,
  PERIPHERAL_TABLE_FIELDS,
  YEAR_TABLE_FIELD,
} from "../constants";
import { Attribute, Category } from "../../../store/model/interfaces";
import { isPeripheral } from "./equipmentManagementHelpers";

const generateAttributesTableHeaders = (attributes: Attribute[]) => {
  return attributes.slice(0, 1).map(({ name: id, label }) => ({ id, label }));
};

const generateTableFieldsForCategories = (
  categories: Category[],
  setSelectedEquipementId: (_id: number | null) => void
) => {
  const equipmentNameHead = {
    id: "name",
    label: "Type",
    rowElement: (value: GenericTableData) => (
      <Link
        data-testid={`link-row-table-${value["id"]}`}
        onClick={() => setSelectedEquipementId(value["id"])}
      >
        {value["name"]}
      </Link>
    ),
  };

  const headersAllCategories: CategoryDataHeadByCategoryName = {};

  categories.forEach((category) => {
    let tableFields;
    if (isPeripheral(category.name)) {
      tableFields = [equipmentNameHead, ...PERIPHERAL_TABLE_FIELDS];
    } else {
      tableFields = [
        ID_TABLE_FIELD,
        equipmentNameHead,
        YEAR_TABLE_FIELD,
        ...generateAttributesTableHeaders(category.attributes),
        ASSIGNED_TO_TABLE_FIELD,
      ];
    }

    headersAllCategories[category.name] = tableFields;
  });

  return headersAllCategories;
};

export const generateTableHeaderFn = (
  categoryId: number,
  setSelectedEquipementId: (_id: number | null) => void,
  categories: Category[]
) => {
  const headersAllCategories: CategoryDataHeadByCategoryName =
    generateTableFieldsForCategories(categories, setSelectedEquipementId);
  const headersByCategoryId: HeadByCategoryId = categories.reduce(
    (headers, { id, name }) => {
      headers = {
        ...headers,
        [id]: [
          ...(headersAllCategories[name] || []).map((header, index) => ({
            ...header,
            isFirst: index == 0,
            numeric: index != 0,
          })),
        ],
      };

      return headers;
    },
    {}
  );

  return headersByCategoryId[categoryId];
};
