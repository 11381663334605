import { ReadFileProps } from "../interfaces";
import { validate, validateCSVColumns } from "./";

export const readFile = ({
  setIsValid,
  setGeneralErrors,
  setFileContent,
  setButtonDisabled,
  setErrors,
  category,
  categoryBySubcategoryName,
  file,
  officeBySeatName,
  ownerByName,
  peopleByEmail,
  statusByName,
}: ReadFileProps) => {
  const reader = new FileReader();
  if (file) {
    reader.onload = async (e) => {
      setGeneralErrors([]);
      const setErrorsCSV = (errorList: string[]) => {
        setGeneralErrors(errorList);
        if (errorList.length) {
          setIsValid(false);
        } else {
          setIsValid(true);
        }
      };
      const fileContent = e.target?.result as string;
      validateCSVColumns(fileContent, category, setErrorsCSV);

      const validateResults = await validate({
        category,
        categoryBySubcategoryName,
        officeBySeatName,
        fileContent,
        ownerByName,
        peopleByEmail,
        statusByName,
      });
      if (validateResults.length) {
        setIsValid(false);
        setErrors(validateResults);
      } else {
        setIsValid(true);
        setErrors([]);
      }

      setFileContent(fileContent);
      setButtonDisabled(false);
    };
    reader.readAsText(file);
  }
};
